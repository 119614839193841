
<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {csvParse} from "d3-dsv";
import {ref, computed} from "vue";
import {useStore} from "vuex";

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  // props : ["country"],


  // beforeRouteEnter : async (to, from) =>{
    
  // },

  setup(){
    const fileRef      = ref(null);
    const store        = useStore();
    const CSVValidKeys = ref([]);
    const CSVdata      = ref([]);
    const showSuccess  = ref(null);
    const showError    = ref(null);
    const isAdmin      = computed( () => store.state.token_type == 1);
    const country      = ref(store.state.token_country);

    const handleCSV    = event =>{
      const file    = event.target.files[0];
      const reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onerror = () => console.log("error");
      reader.onload = event => {
        const data    = csvParse(event.target.result);
        const isValid = store.getters.updateCSVValidKeys(data);
        CSVValidKeys.value = isValid;
        if(isValid) CSVdata.value = data;//store.getters.getCSVValuesForStaging(data, props.country);
      }
    }

    const clearIndices = () => {
      CSVdata.value = [];
      fileRef.value.value = "";
    }

    const saveIndices = e => {
      e.preventDefault();
      if(!CSVdata.value.length){
        return false;
      }

      if(isAdmin.value){
        for(let i of CSVdata.value){
          i.country = country.value;
        }
      }

      store.dispatch("saveIndices", CSVdata.value)
      .then( res => {
        console.log("res:", res.data);
        showSuccess.value = true;
        setTimeout(() => {
          clearIndices();
          showSuccess.value = null;
        }, 3000)
      })
      .catch( () => {
        showError.value = true;
        setTimeout(() => showError.value = null, 3000)
      })
    }

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      handleCSV,
      CSVValidKeys,
      CSVdata,
      showSuccess,
      showError,
      saveIndices,
      clearIndices,
      fileRef,
      isAdmin,
      countries : store.state.countries,
      country
    }
  }
}
</script>
<template>
<div class="row"> 
  <div class="col-12">
    <h2>Actualizar indices</h2>
    <transition name="fade">
      <p v-if="showSuccess" class="ob_alert success">Los datos se han actualizado</p>
    </transition>
    <form @submit.prevent>
      <p v-if="isAdmin">
        <label>País:</label>
        <select v-model="country">
          <option :value="c.id" v-for="c of countries" :key="'c-' + c.id">{{c.name}}</option>
        </select>
      </p>
      <p>Selecciona un archivo csv para actualizar los índices <input ref="fileRef" type="file" accept=".csv" v-on:change="handleCSV" required /></p>
      <p v-if="CSVValidKeys == null" class="ob_alert danger">El csv no tiene el formato correcto :/ <br>
      (puede que le falte la columna de año, que venga vacío o que los nombres de las columnas no correspondan a la guía)<br>
      </p>

      <div v-if="CSVValidKeys">
        <table class="table">
          <thead>
            <tr>
              <th>año</th>
              <th>indicador</th>
              <th>valor</th>
              <!-- <th>comentarios</th> -->
              <!-- <th>opciones</th> -->
            </tr>
          </thead>
          <tbody>
            <!-- <tr v-for="indice of CSVdata" :class="indice.status" :key="`${indice.name}-${indice.year}`"> -->
              <tr v-for="indice of CSVdata" :key="`${indice.name}-${indice.year}`">
              <td>{{indice["año"]}}</td>
              <td>{{indice["indicador"]}}</td>
              <td>{{indice["valor"]}}</td>
              <!-- <td>{{indice.message}}</td> -->
              <!-- <td></td> -->
            </tr>
          </tbody>
        </table>
        <p>
          <button v-on:click.prevent="clearIndices" class="od_btn_noindex">Cancelar</button>  <button v-on:click.prevent="saveIndices" class="od_btn_noindex">Guardar</button>
        </p>
      </div>
    </form>
    <div class="ip_separator bottom"></div>
    <p>Descarga un ejemplo del csv <a href="/indices-ejemplo.csv" class="od_btn_noindex">aquí</a></p>
    <div class="ip_separator bottom"></div>
    <p>Consulta el nombre de las columnas <a href="/indices-full-final.csv" class="od_btn_noindex">aquí</a></p>
  </div>
</div> 
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>